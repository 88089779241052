import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  ContractorPortalFetchInvoice,
  ContractorPortalFetchInvoices,
} from 'src/constants/actionTypes'
import { ApiService, callContractorPortalSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { ContractorPortalInvoice } from 'src/interfaces/contractorPortal'

interface FetchInvoicesResponse {
  total: number
  invoices: ContractorPortalInvoice[]
}

function* onFetchInvoices(action: AsyncAction<void, FetchInvoicesResponse>) {
  try {
    const { payload }: { payload: FetchInvoicesResponse } = yield call(
      callContractorPortalSecureApi,
      Endpoints.ContractorPortalFetchInvoices,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ContractorPortalFetchInvoices.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch invoices'

    yield put(ContractorPortalFetchInvoices.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchInvoiceRequest {
  invoiceId: string
}

function* onFetchInvoice(action: AsyncAction<FetchInvoiceRequest, Buffer>) {
  try {
    const { payload }: { payload: Buffer } = yield call(
      callContractorPortalSecureApi,
      Endpoints.ContractorPortalFetchInvoice,
      {
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':invoiceId',
            value: action.payload.invoiceId,
          },
        ],
        returnBuffer: true,
      },
    )

    yield put(ContractorPortalFetchInvoice.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch invoice'

    yield put(ContractorPortalFetchInvoice.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* contractorPortalInvoicesSaga() {
  yield takeEvery(ContractorPortalFetchInvoices.type.REQUEST, onFetchInvoices)
  yield takeEvery(ContractorPortalFetchInvoice.type.REQUEST, onFetchInvoice)
}
