import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ContractorPortalFetchDocuments } from 'src/constants/actionTypes'
import { ApiService, callContractorPortalSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { ContractorPortalDocument } from 'src/interfaces/contractorPortal'

interface FetchDocumentsResponse {
  total: number
  reports: ContractorPortalDocument[]
}

function* onFetchDocuments(action: AsyncAction<void, FetchDocumentsResponse>) {
  try {
    const { payload }: { payload: FetchDocumentsResponse } = yield call(
      callContractorPortalSecureApi,
      Endpoints.ContractorPortalFetchDocuments,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ContractorPortalFetchDocuments.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch documents'

    yield put(ContractorPortalFetchDocuments.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* contractorPortalDocumentsSaga() {
  yield takeEvery(ContractorPortalFetchDocuments.type.REQUEST, onFetchDocuments)
}
