import { ContractorPortalLogout, ContractorPortalFetchDocuments } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ContractorPortalDocumentsState } from './types'

const getInitialState = (): ContractorPortalDocumentsState => ({
  contractorPortalDocuments: [],
  contractorPortalDocumentsCount: 0,
})

export const contractorPortalDocumentsReducer = (
  state: ContractorPortalDocumentsState = getInitialState(),
  action: Action<any>,
): ContractorPortalDocumentsState => {
  switch (action.type) {
    case ContractorPortalFetchDocuments.type.SUCCESS:
      return {
        ...state,
        contractorPortalDocuments: action.payload.reports,
        contractorPortalDocumentsCount: action.payload.total,
      }
    case ContractorPortalLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
