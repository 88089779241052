import { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { Typography } from '@mui/material'
import { ContractorPortalDocument } from 'src/interfaces/contractorPortal'
import tw from 'twin.macro'
import { ViewMinor } from '@shopify/polaris-icons'
import { MenuActionItem } from 'src/interfaces/ui'
import dayjs from 'dayjs'
import { ActionButton } from '../../shared/ActionButton'
import { ActionMenu } from '../../shared/ActionMenu'
import { ContractorPortalDocumentModal } from './ContractorPortalDocumentModal'
import { InfoContainer } from 'src/components/shared/InfoContainer'

interface Props {
  document: ContractorPortalDocument
  isFirstItem?: boolean
}

const ContractorPortalDocumentItemComponent = ({ document, isFirstItem }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState<boolean>(false)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleAction = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    const anchor = event.currentTarget

    setAnchorEl(anchor)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const closeDocumentModal = useCallback(() => {
    setIsDocumentModalOpen(false)
  }, [])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    const res = [
      {
        label: 'View document',
        icon: <ViewMinor width={20} height={20} />,
        href: document.fileUrl,
        onClick: () => {
          handleCloseMenu()
        },
      },
    ]

    return res
  }, [document, handleCloseMenu])

  return (
    <div
      css={[
        tw`p-4 bg-white border-0 border-t border-solid border-[#EDEDED]`,
        isFirstItem && tw`border-none`,
      ]}
    >
      <div css={tw`flex items-center flex-row justify-between`}>
        <div css={tw`max-w-[60%]`}>
          <Typography variant="h4" css={tw`mb-0.5 [word-wrap: break-word]`}>
            {document.fileName + document.fileName + document.fileName}
          </Typography>
          <Typography color="#847E96" css={tw`mb-0.5`}>
            {dayjs.unix(document.createdAt).format('MMMM DD, YYYY')}
          </Typography>
        </div>
        <ActionButton selected={!!anchorEl} color="grey" text="Actions" onClick={handleAction} />
        <ActionMenu
          anchorEl={anchorEl}
          items={actionItems}
          isOpen={isMenuOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleCloseMenu}
        />
        <ContractorPortalDocumentModal
          isOpen={isDocumentModalOpen}
          document={document.fileUrl}
          onClose={closeDocumentModal}
        />
      </div>
      {document.type === 'form_1099' && (
        <InfoContainer className={tw`mt-4`}>
          This document includes all of your earnings distributed by GigSafe
        </InfoContainer>
      )}
    </div>
  )
}

export const ContractorPortalDocumentItem = memo(ContractorPortalDocumentItemComponent)
