import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { Modal, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { useDispatch, useSelector } from 'react-redux'
import { ContractorPortalSwitchPartner } from 'src/constants/actionTypes'
import { Button } from '../shared/Button'
import { getContractorPortalPartners } from 'src/selectors/contractorPortal'
import { ContractorPortalPartner } from 'src/interfaces/contractorPortal'
import { ContractorPortalPartnerItem } from '../contractor-portal/ContractorPortalPartnerItem'
import { createAsyncAction } from 'src/utils/reduxUtils'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const SwitchPartnerModalComponent = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()

  const partners = useSelector(getContractorPortalPartners)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleSwitchPartner = useCallback(async (partner: ContractorPortalPartner) => {
    await createAsyncAction(
      dispatch,
      ContractorPortalSwitchPartner.request({ partnerId: partner.id }),
    )

    onClose()
  }, [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex flex-col justify-end xsm:justify-center items-center backdrop-blur`}
    >
      <div
        css={tw`flex-col py-4 pt-6 bg-white rounded-2xl rounded-b-none xsm:rounded-b-2xl w-full xsm:w-[390px]`}
      >
        <div css={tw`px-4`}>
          <Typography variant="h2">Switch partner</Typography>
          <Typography css={tw`mt-2 mb-6`} color={colors.SHADES_GREY_400}>
            Select the partner you want to view.
          </Typography>
        </div>
        {partners.map((item) => (
          <ContractorPortalPartnerItem key={item.id} partner={item} onClick={handleSwitchPartner} />
        ))}
        <div css={tw`px-4 pt-4`}>
          <Button fullWidth onClick={onClose} color="black">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const SwitchPartnerModal = memo(SwitchPartnerModalComponent)
