import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction, Action } from 'src/utils/reduxUtils'
import {
  OnboardingFetchDriver,
  OnboardingSignInPhone,
  OnboardingSignInEnterCode,
  OnboardingSignInResendCode,
  OnboardingSetAuthToken,
  OnboardingLogout,
  OnboardingSetDriver,
  OnboardingSignUp,
} from 'src/constants/actionTypes'
import { ApiService, callApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { authService } from 'src/services/AuthService'

interface SignUpRequest {
  partnerName: string
  phone: string
  email: string
  firstName: string
  lastName: string
  tagNames: string[]
  partnerMarketId: string
  onboardingTemplateId: string
  referral: string
}

function* onSignUp(action: AsyncAction<SignUpRequest>) {
  const {
    partnerName,
    phone,
    email,
    firstName,
    lastName,
    partnerMarketId,
    onboardingTemplateId,
    tagNames,
    referral,
  } = action.payload

  try {
    const { payload }: { payload: { smsSend: boolean } } = yield call(
      callApi,
      Endpoints.OnboardingSignUp,
      {
        method: 'POST',
        body: {
          phone,
          partnerName,
          email,
          firstName,
          lastName,
          partnerMarketId: partnerMarketId.trim(),
          onboardingTemplateId: onboardingTemplateId.trim(),
          tagNames,
          referral,
        },
        apiService: ApiService.Paraworks,
      },
    )

    if (payload?.smsSend === false) {
      throw {
        payload: {
          message: 'No partner driver found with the provided phone number',
        },
      }
    }

    yield put(OnboardingSignUp.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to sign in'

    yield put(OnboardingSignUp.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SignInPhoneRequest {
  partnerName: string
  phone: string
}

function* onSignInPhone(action: AsyncAction<SignInPhoneRequest>) {
  const { partnerName, phone } = action.payload

  try {
    const { payload }: { payload: { smsSend: boolean } } = yield call(
      callApi,
      Endpoints.OnboardingSignInPhone,
      {
        method: 'POST',
        body: {
          phone,
          partnerName,
        },
        apiService: ApiService.Paraworks,
      },
    )

    if (payload?.smsSend === false) {
      throw {
        payload: {
          message: 'No partner driver found with the provided phone number',
        },
      }
    }

    yield put(OnboardingSignInPhone.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to signIn'

    yield put(OnboardingSignInPhone.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SignInEnterCodeRequest {
  code: string
  partnerName: string
  phone: string
}

interface SignInEnterCodePayload {
  token: string
}

function* onSignInEnterCode(action: AsyncAction<SignInEnterCodeRequest>) {
  const { partnerName, code, phone } = action.payload

  try {
    const { payload }: { payload: SignInEnterCodePayload } = yield call(
      callApi,
      Endpoints.OnboardingSignInEnterCode,
      {
        method: 'POST',
        body: {
          code,
          partnerName,
          phone,
        },
        apiService: ApiService.Paraworks,
      },
    )

    yield put(OnboardingSignInEnterCode.success())
    yield put(OnboardingSetAuthToken.request(payload.token))
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to sign in'

    yield put(OnboardingSignInPhone.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverToken(action: Action<string>) {
  const authToken = action.payload

  yield put(OnboardingSetAuthToken.success(authToken))

  authService.setStoredOnboardingAuthToken(authToken)
}

function* onLogout() {
  yield put(OnboardingSetDriver.request(null))
  yield put(OnboardingSetAuthToken.request(null))
  yield put(OnboardingLogout.success())
}

export function* onboardingAuthSaga() {
  yield takeEvery(
    [OnboardingSignInPhone.type.REQUEST, OnboardingSignInResendCode.type.REQUEST],
    onSignInPhone,
  )
  yield takeEvery(OnboardingSignInEnterCode.type.REQUEST, onSignInEnterCode)
  yield takeEvery(OnboardingSignUp.type.REQUEST, onSignUp)
  yield takeEvery(OnboardingSetAuthToken.type.REQUEST, onSetDriverToken)
  yield takeEvery(OnboardingLogout.type.REQUEST, onLogout)
}
