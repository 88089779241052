import { State } from '../reducers/types'
import {
  ContractorPortalPartner,
  ContractorPortalInvoice,
  ContractorPortalDocument,
} from 'src/interfaces/contractorPortal'

export const getContractorPortalPartners = (state: State): ContractorPortalPartner[] =>
  state.contractorPortalPartners.contractorPortalPartners

export const getContractorPortalInvoices = (state: State): ContractorPortalInvoice[] =>
  state.contractorPortalInvoices.contractorPortalInvoices

export const getContractorPortalInvoicesCount = (state: State): number =>
  state.contractorPortalInvoices.contractorPortalInvoicesCount

export const getContractorPortalDocuments = (state: State): ContractorPortalDocument[] =>
  state.contractorPortalDocuments.contractorPortalDocuments

export const getContractorPortalDocumentsCount = (state: State): number =>
  state.contractorPortalDocuments.contractorPortalDocumentsCount
