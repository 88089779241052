import { combineReducers } from 'redux'
import { onboardingAuthReducer } from './onboardingAuth'
import { onboardingDriverReducer } from './onboardingDriver'
import { contractorPortalAuthReducer } from './contractorPortalAuth'
import { contractorPortalDriverReducer } from './contractorPortalDriver'
import { contractorPortalPartnersReducer } from './contractorPortalPartners'
import { contractorPortalInvoicesReducer } from './contractorPortalInvoices'
import { contractorPortalDocumentsReducer } from './contractorPortalDocuments'
import { recollectionAuthReducer } from './recollectionAuth'
import { recollectionDriverReducer } from './recollectionDriver'
import { documentsReducer } from './documents'
import { partnerReducer } from './partner'

export const rootReducer = combineReducers({
  recollectionAuth: recollectionAuthReducer,
  recollectionDriver: recollectionDriverReducer,
  onboardingAuth: onboardingAuthReducer,
  onboardingDriver: onboardingDriverReducer,
  contractorPortalAuth: contractorPortalAuthReducer,
  contractorPortalDriver: contractorPortalDriverReducer,
  contractorPortalPartners: contractorPortalPartnersReducer,
  contractorPortalInvoices: contractorPortalInvoicesReducer,
  contractorPortalDocuments: contractorPortalDocumentsReducer,
  documents: documentsReducer,
  partner: partnerReducer,
})
