import React, { memo } from 'react'
import { FilePreviewModal } from 'src/components/shared/FilePreviewModal'

interface Props {
  isOpen?: boolean
  invoice?: any
  onClose(): void
}

const ContractorPortalInvoiceModalComponent = ({ isOpen, invoice, onClose }: Props) => (
  <FilePreviewModal title="Invoice" file={invoice} isOpen={isOpen} onClose={onClose} />
)

export const ContractorPortalInvoiceModal = memo(ContractorPortalInvoiceModalComponent)
