import React, { memo } from 'react'
import { FilePreviewModal } from 'src/components/shared/FilePreviewModal'

interface Props {
  isOpen?: boolean
  document?: any
  onClose(): void
}

const ContractorPortalDocumentModalComponent = ({ isOpen, document, onClose }: Props) => (
  <FilePreviewModal title="Document" file={document} isOpen={isOpen} onClose={onClose} />
)

export const ContractorPortalDocumentModal = memo(ContractorPortalDocumentModalComponent)
